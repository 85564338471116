<template>
  <div v-if="brand" class="wrap">
    <header class="layout_header">
      <div class="hinner">
        <nav class="gnb">
          <a class="back absolute" href="javascript:history.back();"></a>

          <h2 class="new_title">
            <span class="brand_name">{{ brand.den_name }}</span>
          </h2>
          <search-cart-icons />
        </nav>
      </div>
    </header>
    <div class="layout_body brand_detail_layout">
      <!-- 필터 선택한 후 -->
      <filter-slider
        v-if="filterSlideItems.length > 0"
        :items="filterSlideItems"
        @click="handleClickTag"
      />
      <div class="brand_prod_box">
        <image-bg-src :src="brand.seller_picture" class="prod_img" />
        <div class="brand_prod_tit disflex">
          <h4>{{ brand.den_name }}</h4>
          <book-mark-button
            :value="isPick"
            :pickItemId="brand.den_id"
            type="p"
            class="bookmark list"
            @update="handleUpdatePickItem"
          >
            {{ brand.count }}
          </book-mark-button>
        </div>
        <button class="brand_cou disflex" @click="isShowBrandCoupons = true">
          <span>{{ $t('Brand.BrandGetCoupon') }}</span>
          <span class="download_btn"></span>
        </button>
      </div>

      <article class="look_product">
        <div class="prod_swiper noswiper_prod">
          <h6 class="pb14">
            {{ $t('Brand.Total') }} <strong>{{ count }}</strong>
            {{ $t('Brand.CountProduct') }}
          </h6>
          <ul id="tab-1" class="product_con tab-content current">
            <li v-for="item in items" :key="item.cit_id">
              <product-item class="prod_img" :item="item" :bookMark="true" />
            </li>
          </ul>
        </div>
      </article>

      <!-- body : END -->
      <sild-footer />

      <div class="disflex btn_box">
        <button
          type="button"
          class="order_filter btn_lineblack btn_pop_open"
          @click="isShowSort = true"
        >
          {{ $t('Brand.OrderBy') }}
        </button>
        <button
          type="button"
          class="filter_filter btn_lineblack btn_pop_open"
          @click="isShowFilterPopup = true"
        >
          {{ $t('Brand.ItemFilter') }}
        </button>
      </div>
    </div>

    <brand-coupons
      v-if="isShowBrandCoupons"
      :den_id="den_id"
      @close="isShowBrandCoupons = false"
    />

    <items-filter-popup
      v-if="isShowFilterPopup"
      v-model="filters"
      :tabOptions="tabOptions"
      @close="isShowFilterPopup = false"
      @input="handleInputFilter"
    />
    <sort-popup
      v-if="isShowSort"
      :title="$t('Brand.OrderBy')"
      :items="sortItems"
      @close="isShowSort = false"
      @select="handleSelectedSort"
    />
    <infinite-loading
      v-if="hasMore"
      :identifier="infiniteId"
      @infinite="loadDatas"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import BrandAPI from '../../apis/BrandAPI';
import CouponAPI from '../../apis/CouponAPI';
import useUserInfo from '../../composables/user/useUserInfo';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import SortPopup from '../../components/popups/SortPopup.vue';
import ItemsFilterPopup from '../items/components/ItemsFilterPopup.vue';
import ProductAPI from '../../apis/ProductAPI';
import SearchAPI from '../../apis/SearchAPI';
import InfiniteLoading from 'vue-infinite-loading';
import ImageBgSrc from '../../components/ui/ImageBlock/ImageBgSrc.vue';
import BookMarkButton from '../../components/ui/Buttons/BookMarkButton.vue';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import usePick from '@/composables/user/usePick';
import BrandCoupons from '../../components/popups/BrandCoupons.vue';
import SearchCartIcons from '../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';
import filterItems from '@/consts/FilterItems';
import FilterSlider from '../../components/ui/FilterSlider/FilterSlider.vue';

export default {
  components: {
    SildFooter,
    SortPopup,
    ItemsFilterPopup,
    InfiniteLoading,
    ImageBgSrc,
    BookMarkButton,
    ProductItem,
    BrandCoupons,
    SearchCartIcons,
    FilterSlider,
  },
  setup(props, context) {
    const den_id = context.root.$route.params.id;

    const { info } = useUserInfo();
    const { getUserPick } = usePick();

    const state = reactive({
      brand: null,
      items: [],
      filters: {},
      hasMore: true,
      isShowSort: false,
      isShowFilterPopup: false,
      isShowBrandCoupons: false,
      infiniteId: Date.now(),
      count: 0,
      coupons: undefined,
      isPick: false,
      filterSlideItems: [],
      tabOptions: 'BrandDetailView',
    });

    const params = {
      page: 0,
      limit: 10,
      den_id,
      mem_id: info?.mem_id ?? 0,
    };

    const loadBrand = async () => {
      const { data } = await BrandAPI.getBrand({
        mem_id: info?.mem_id ?? 0,
        den_id,
      });

      await loadPickBrands(data.result_data);
      state.brand = data.result_data;
    };

    const loadPickBrands = async (brand) => {
      const brands = await getUserPick('brands');

      state.isPick =
        brands.findIndex((item) => item.den_id === brand.den_id) > -1;
    };

    const loadDatas = async (attr) => {
      const search = createSearchParams();
      console.log(search);
      try {
        const { data } = await SearchAPI.searchItem(search);
        console.log('loaddatas');
        console.log(data);
        state.items = [...state.items, ...data.body.result.result_data];
        state.count = data.body.result.count;
        if (data.body.result.result_data.length !== params.limit) {
          attr?.complete();
          state.hasMore = false;
        } else {
          params.page++;
          attr?.loaded();
        }
      } catch (error) {
        console.log(error);
        const { data } = await ProductAPI.getItemsByType(search);
        state.items = [...state.items, ...data.result_data];
        if (data.result_data.length !== params.limit) {
          attr?.complete();
          state.hasMore = false;
        } else {
          params.page++;
          attr?.loaded();
        }
      }
      const rr = await CouponAPI.getBrandCoupons({
        mem_id: info?.mem_id,
        den_id: props.den_id,
      });
      console.log('state.coupons');
      console.log(rr);

      state.coupons = rr.data.result_data;
    };

    let filterValues = null;

    (async () => {
      filterValues = await filterItems.getItemFilterOptions();
    })();

    const clearSearchParams = () => {
      params.page = 0;
      state.items = [];
      state.hasMore = true;
      state.infiniteId = Date.now();
    };

    const createSearchParams = () => {
      console.log('createSearchParams');
      console.log(state.filters);
      const filters = {
        ...params,
        type: state.type,
      };

      Object.keys(state.filters).forEach((key) => {
        const value = state.filters[key];

        filters[key] = createSearchParamValue(key, value);
      });
      console.log('createSearchParams result');
      console.log(filters);
      return filters;
    };

    const createSearchParamValue = (key, value) => {
      switch (key) {
        case 'category1':
        case 'category2':
        case 'category3':
          return Object.entries(value)
            .filter((value) => {
              console.log(value);
              if (value[1]) return value;
            })
            .map((value) => {
              return value[0];
            });

        case 'concept':
          return value.filter((concept) => concept);

        case 'age':
          return value.filter((age) => age);

        case 'color':
          return value.filter((color) => color);

        case 'style':
          return value.filter((style) => style);

        case 'price':
        case 'soldOut':
          return value;

        default:
          return '';
      }
    };

    loadBrand();

    const getFilterTags = (filters) => {
      const filterItems = [];
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        switch (key) {
          case 'category1':
          case 'category2':
          case 'category3':
            console.log('category123');
            console.log(filters);
            console.log(filterValues);
            for (const [idAndLabel, checked] of Object.entries(value)) {
              if (checked) {
                console.log('checked filterValues[category][idx]');
                console.log(idAndLabel);
                const label = idAndLabel.split('#')[1];
                filterItems.push({
                  path: `${key}$$${idAndLabel}`,
                  label: label,
                });
              }
            }
            break;
          case 'concept':
          case 'age':
          case 'color':
            value.forEach((checked, idx) => {
              if (checked) {
                filterItems.push({
                  path: `${key}$$${idx}`,
                  label: filterValues[key][idx].label,
                });
              }
            });
            break;

          case 'price':
            value &&
              filterItems.push({
                path: key,
                label: `${value[0]}~${value[1]}`,
              });
            break;

          case 'soldOut':
            value &&
              filterItems.push({
                path: key,
                label: '품절 제외',
              });
            break;

          default:
            return '';
        }
      });
      console.log('filterItems');
      console.log(filterItems);
      return filterItems;
    };
    const sortItems = [
      {
        key: '',
        label: context.root.$t('Brand.OrderByRecommand'),
      },
      {
        key: 'new_dtm',
        label: context.root.$t('Brand.OrderByNew'),
      },
      {
        key: 'upd_dtm',
        label: context.root.$t('Brand.OrderByUpdate'),
      },
      {
        key: 'cit_price_desc',
        label: context.root.$t('Brand.OrderByHigh'),
      },
      {
        key: 'cit_price_asc',
        label: context.root.$t('Brand.OrderByLow'),
      },
      {
        key: 'sale',
        label: context.root.$t('Brand.OrderByDiscount'),
      },
    ];
    return {
      den_id,
      ...toRefs(state),
      sortItems,
      loadDatas,
      handleInputFilter(filters) {
        console.log('handleInputFilter');
        console.log(filters);
        state.filterSlideItems = getFilterTags(filters);
        state.isShowFilterPopup = false;
        clearSearchParams();
      },
      handleSelectedSort(item) {
        state.isShowSort = false;
        params.orderBy = item.key;
        localStorage.setItem('item_sort_key', item.key);
        clearSearchParams();
      },
      handleUpdatePickItem() {
        loadBrand();
      },
      handleClickTag(item) {
        console.log('handleClickTag');
        console.log(item);
        const paths = item.path.split('$$');
        console.log(state.filters);
        const filters = { ...state.filters };
        console.log(paths);
        console.log(filters);

        if (paths.length > 1) {
          filters[paths[0]][paths[1]] = false;
        }

        state.filters = filters;

        state.filterSlideItems = getFilterTags(state.filters);

        clearSearchParams();
      },
    };
  },
};
</script>

<style></style>
